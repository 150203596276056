// 测试请求
import request from "./request";
// 获取验证码
export const dataCode = () =>
  request({
    url: "captchaImage",
  });
// 登录
// export const infoDengLong = (data) =>
//   request({
//     url: "login",
//     data,
//     method: "post",
//   });
// 获取
export const qy = (params) =>
  request({
    url: "test/qy/list",
    method: "get",
    params
  });
// 修改
export const qyput = (params) =>
  request({
    url: "test/qy",
    method: "put",
    data: params
  });
// 删除
export const qydele = (ids) =>
  request({
    url: `test/qy/${ids}`,
    method: "delete",
  });
// 新增
export const qypost = (params) =>
  request({
    url: "test/qy",
    method: "post",
    data: params
  });
// 导出
export const qyspost = (params) =>
  request({
    url: "test/qy/export",
    method: "post",
    data: params,
    responseType: 'blob'
  });
  //   近7天销售网点农药包装销售量排名   /dev-api/bigScreen/sxlpm
  export const paiming = () =>
  request({
    url: "bigScreen/sxlpm",
    method: "get",
  });
  //  已销售农药包装类型占比    bigScreen/bzlxzb
  export const zhanbi = () =>
  request({
    url: "bigScreen/bzlxzb",
    method: "get",
  });
  //  已销售农药品牌类型占比    bigScreen/pplxzb
  export const lx = () =>
  request({
    url: "bigScreen/pplxzb",
    method: "get",
  });
  //  销售回收量对比    bigScreen/xshsldb
  export const db = () =>
  request({
    url: "bigScreen/xshsldb",
    method: "get",
  });
  //  总网点数    bigScreen/zwds
  export const zong = () =>
  request({
    url: "bigScreen/zwds",
    method: "get",
  });
  //  当日回收量    bigScreen/drxshsldb
  export const dr = () =>
  request({
    url: "bigScreen/drxshsldb",
    method: "get",
  });
  //  近七天农药包装清运情况    bigScreen/jqtqyqk
  export const jqtqyqk = () =>
  request({
    url: "bigScreen/jqtqyqk",
    method: "get",
  });
  //  已清运农药包装类型占比    bigScreen/yqybzlxzb
  export const yqybzlxzb = () =>
  request({
    url: "bigScreen/yqybzlxzb",
    method: "get",
  });
  //  保定市各县区包装废弃物回收总量排名    bigScreen/hslpm
  export const hslpm = () =>
  request({
    url: "bigScreen/hslpm",
    method: "get",
  });
  //问题
  //  近七天销售网点回收情况统计   bigScreen/jqthsqk
  export const jqthsqk = () =>
  request({
    url: "bigScreen/jqthsqk",
    method: "get",
  });
  // 近七天农药包装回收类型趋势图   bigScreen/jqtnybzhslx
  export const hslxqst = () =>
  request({
    url: "bigScreen/jqtnybzhslx",
    method: "get",
  });