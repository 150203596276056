import axios from "axios";
import { Message } from "element-ui";
import router from "../router/index";
let count = 0;
export default function request(confing) {
  return new Promise((reslove, reject) => {
    let installs = axios.create({
      // baseURL: "http://81.68.152.108:9620/",//生产
      baseURL: "https://nybzhs.sjzydrj.net/prod-api",//生产
      timeout: 10000,
    });
    // 请求拦截
    // installs.interceptors.request.use((confing) => {
    //   if (localStorage.getItem("userToken")) {
    //     confing.headers.Authorization = "Bearer "+localStorage.getItem("userToken"); //保存token
    //     }
    //     return confing;
    //   });
    // 响应拦截
    // installs.interceptors.response.use((confing) => {
    //   if (confing.data.code == 401) {
    //     if (count == 0) {
    //       Message.error("登录时间过长请重新登录");
    //       router.replace({
    //         path: "/infoData", // 到登录页重新获取token
    //       });
    //       count = 10;
    //     }
    //   } else {
    //     count = 0;
    //   }

    //   return confing.data;
    // });
    //真正的请求
    installs(confing)
      .then((res) => {
        reslove(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });
}
