<template>
  <div class="nongyao">
    <!-- 顶部 -->
    <div class="header">
      <div class="one">
        <img src="../../assets/image/tubiao.png" alt="" />
        <div>农药包装回收数据大屏</div>
      </div>
      <div class="title">保定市农药包装废弃物回收管理平台</div>
      <div class="date">
        {{ data }}
      </div>
    </div>
    <!-- 左侧 -->
    <div class="left">
      <div class="headers">
        <p>近7天销售网点农药包装销售排名（TOP5）</p>
        <Pesticides />
      </div>
      <div class="body">
        <div class="npm">
          <p>已销售农药包装类型占比</p>
          <div ref="echartsINofs" class="echarts"></div>
        </div>
      </div>
      <div class="bottom">
        <div class="npm">
          <p>已销售农药品牌类型占比（TOP5）</p>
          <div ref="echartsINofRightBodyRight" class="echarts"></div>
        </div>
      </div>
    </div>
    <!-- 中间 -->
    <div class="middle">
      <div class="headers">
        <div class="a aa">
          <div class="top">销售量</div>
          <div class="bottomss">{{datas.number}}</div>
        </div>
        <div class="b">
          <div class="top">总回收率</div>
          <!-- <div class="bottomss">{{Math.round(datas.huishou_num/datas.number)}}%</div> -->
          <div class="bottomss">{{(datas.huishou_num/datas.number).toFixed(2)}}%</div>
        </div>
        <div class="c a">
          <div class="bottomss" style="margin-left: 0vw">{{datas.huishou_num}}</div>
          <div class="top" style="margin-left: 1vw">回收量</div>
        </div>
      </div>
      <div class="body">
        <div class="mapWarp">
          <div id="container" ref="container"></div>
        </div>
        <div class="abc">
          <div class="a aa">
            <div v-if="datasss==undefined">1254个</div>
            <div v-else>{{datasss.number}}个</div>
            <div>当日生产量</div>
          </div>
          <div class="a bb">
            <div>{{datass}}个</div>
            <div>销售网点总数</div>
          </div>
          <div class="a cc">
            <div v-if="datasss==undefined">1254个</div>
            <div v-else>{{datasss.huishou_num}}个</div>
            
            <div>当日回收量</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lefts">
          <div class="npm">
            <p>近七天农药包装清运情况</p>
            <div ref="echartsINofsbottom" class="echarts"></div>
          </div>
        </div>
        <div class="rights">
          <div class="npm">
            <p>已清运农药包装类型占比</p>
            <div ref="echartsINofRightBody" class="echarts"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="right">
      <div class="headers">
        <div class="npm">
          <p>保定市各县区包装废弃物回收总量排名（TOP5）</p>
          <div ref="echartsINofrightTop" class="echarts"></div>
        </div>
      </div>
      <div class="body">
        <div class="npm">
          <p>近七天销售网点回收情况统计</p>
          <div ref="echartsINof" class="echarts"></div>
        </div>
      </div>
      <div class="bottom">
        <div class="npm">
          <p>近七天农药包装回收类型趋势图</p>
          <div ref="echartsINofsrightbottomright" class="echarts"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pesticides from "./pesticides.vue";
import { zhanbi,lx,db,zong,dr,jqtqyqk,yqybzlxzb,hslpm,jqthsqk,hslxqst } from "../../network/index";
export default {
  components: {
    Pesticides: pesticides,
  },
  data() {
    return {
      map: null,
      one: {},
      points: null,
      datas: {},
      datass: '',
      datasss: {},
    };
  },
  mounted() {
    // 2021年12月2日 星期四 11:05
    let _this = this;
    this.timer = setInterval(() => {
      // _this.data = new Date().toLocaleString();
      let y = new Date().getFullYear() + "年";
      let m = new Date().getMonth() + 1 + "月";
      let d = new Date().getDate() + "日";
      let day = new Date().getDay();
      let dd = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let days = dd[day];
      let h = new Date().getHours() + ":";
      let mi = new Date().getMinutes();
      if (mi < 10) {
        mi = "0" + mi;
      }
      _this.data = y + m + d + " " + days + " " + h + mi;
    });
    //接口
    this.getinfodata();
    this.getinfodatalx();
    this.getinfodatadb();
    this.getinfodatazong();
    this.getinfodatadr();
    this.getinfodatajqtqyqk();
    this.getinfodatayqybzlxzb();
    this.getinfodatahslpm();
    this.getinfodatahslxqst();
    this.getinfodatajqthsqk();
    //地图
    this.mapList();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    //页面实时更新
    one() {
      // 调用定时器
      this.timer();
      this.rows;
    },
  },
  // 页面销毁后 停止计时器
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
     //地图
    mapList() {
      this.map = new BMap.Map(this.$refs.container); // 创建Map实例
      // this.map = new BMap.Map('container'); // 创建Map实例
      // this.points = new BMapGL.Point(116.404, 39.925);
      this.map.centerAndZoom(new BMap.Point(115.47, 38.868), 10);
      //滚轮放大
      this.map.enableScrollWheelZoom(true);
      // 创建点标记
      var marker = new BMap.Marker(new BMap.Point(115.47, 38.868));
      this.map.addOverlay(marker);
      // 创建信息窗口
      var opts = {
        width: 200,
        height: 100,
        title: "保定市第一农资站",
      };
      var infoWindow = new BMap.InfoWindow("地址：保定市", opts);
      // 点标记添加点击事件
      marker.addEventListener("click", function () {
        this.map.openInfoWindow(infoWindow, new BMap.Point(115.47, 38.868)); // 开启信息窗口
      });

      // 创建点标记
      var markers = new BMap.Marker(new BMap.Point(115.6, 39.0));
      this.map.addOverlay(markers);
      // 创建信息窗口
      var optss = {
        width: 200,
        height: 100,
        title: "保定市第二农资站",
      };
      var infoWindows = new BMap.InfoWindow("地址：保定市", optss);
      // 点标记添加点击事件
      markers.addEventListener("click", function () {
        this.map.openInfoWindow(infoWindows, new BMap.Point(115.6, 39.0)); // 开启信息窗口
      });

      //覆盖到地图上的阴影
      // this.getBoundary();
    },
    //已销售农药包装类型占比
    async getinfodata() {
      const { code, data, msg } = await zhanbi();
      let datas = data.data;
      let unit = []; //类型
      let number = []; //数量
      for (const item of datas) {
        unit.push(item.unit);
        number.push(item.number);
      }
      this.handEchartLeft(unit,number);
    },
    //已销售农药品牌类型占比
    async getinfodatalx() {
      const { code, data, msg } = await lx();
      let datas = data.data;
      let nylb = []; //类型
      let number = []; //数量
      for (const item of datas) {
        nylb.push(item.nylb);
        number.push(item.number);
      }
      this.RightBodyRight(nylb,number);
    },
    //销售回收量对比
    async getinfodatadb() {
      const { code, data, msg } = await db();
      this.datas = data.data;
    },
    //总网点数
    async getinfodatazong() {
      const { code, data, msg } = await zong();
      this.datass = data.data;
    },
    //今日回收量
    async getinfodatadr() {
      const { code, data, msg } = await dr();
      this.datasss = data.data;
    },
    //近七天农药包装清运情况      jqtqyqk
    async getinfodatajqtqyqk() {
      const { code, data, msg } = await jqtqyqk();
      let datas = data.data;
      let count = []; //类型
      let days = []; //数量
      for (const item of datas) {
        count.push(item.count);
        days.push(item.days);
      }
      this.handEchartLeftBottom(count,days);
    },
    //已清运农药包装类型占比   
    async getinfodatayqybzlxzb() {
      const { code, data, msg } = await yqybzlxzb();
      let datas = data.data;
      let unit = []; //类型
      let number = []; //数量
      for (const item of datas) {
        unit.push(item.unit);
        number.push(item.number);
      }
      this.RightBody(unit,number);
    },
    //保定市各县区包装废弃物回收总量排名   
    async getinfodatahslpm() {
      const { code, data, msg } = await hslpm();
      let datas = data.data;
      let area = []; //类型
      let count = []; //数量
      for (const item of datas) {
        area.push(item.area);
        count.push(item.count);
      }
      this.rightTop(area,count);
    },
    //近七天农药包装回收类型趋势图   
    async getinfodatahslxqst() {
      const { code, data, msg } = await hslxqst();
      let datas = data.data;
      let daicount = []; //类型
      let pingcount = []; //数量
      let days = []; //数量
      for (const item of datas) {
        daicount.push(item.daicount);
        pingcount.push(item.pingcount);
        days.push(item.days);
      }
      this.RightbottomRight(daicount,pingcount,days);
    },
    //近七天销售网点回收情况统计   
    async getinfodatajqthsqk() {
      const { code, data, msg } = await jqthsqk();
      let datas = data.data;
      let days = []; //类型
      let count = []; //数量
      for (const item of datas) {
        days.push(item.days);
        count.push(item.count);
      }
      this.handEchart(days,count);
    },
    //页面实时更新
    timer() {
      return setTimeout(() => {
        this.getDataone();
      }, 1000);
    },

    //echares表格

    // 近七天销售网点回收情况统计
    handEchart(days,count) {
      let myechart = this.$echarts.init(this.$refs.echartsINof);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["回收数量", "回收率"],
          textStyle: {
            color: "#fff",
          },
        },
        textStyle: {
          color: "#fff",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "18%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            // data: days,
            data: ['2022-03-02','2022-03-01','2022-02-28','2022-02-27','2022-02-26','2022-02-25','2022-02-24'],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "(个)",
            nameTextStyle: {
              padding: [0, 0, 0, -35], // 四个数字分别为上右下左与原位置距离
              fontSize: 10,
            },
            // min: 0,
            // max: 250,
          },
          {
            type: "value",
            name: "(%)",
            nameTextStyle: {
              padding: [0, 0, 0, 30], // 四个数字分别为上右下左与原位置距离
              fontSize: 10,
            },
          //   min: 0,
          //   max: 25,
          },
        ],
        series: [
          {
            name: "回收数量",
            type: "bar",
            // data: count,
            data: [852,975,658,284,665,982,456],
            itemStyle: {
              color: "#DE11EB",
            },
          },
          {
            name: "回收率",
            type: "line",
            yAxisIndex: 1,
            // data: count,
            data: [80,60,90,89,45,78,82],
            itemStyle: {
              color: "#00A0E9",
            },
          },
        ],
      };
      myechart.setOption(option);
    },
    // 已销售农药包装类型占比
    handEchartLeft(unit,number) {
      let myechart = this.$echarts.init(this.$refs.echartsINofs);
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          // data: ["回收数量", "回收率"],
          textStyle: {
            color: "#fff",
            fontSize:15,
          },
        },
        textStyle: {
          color: "#fff",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data:number,
            data: [
              {
                value: number[0],
                name: unit[0],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
              {
                value: number[1],
                name: unit[1],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
              {
                value: number[2],
                name: unit[2],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
              {
                value: number[3],
                name: unit[3],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
              {
                value: number[4],
                name: unit[4],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
              {
                value: number[5],
                name: unit[5],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
              {
                value: number[6],
                name: unit[6],
                // itemStyle: {
                //   color: "#F86D18",
                // },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myechart.setOption(option);
    },
    // 近七天农药包装清运情况
    handEchartLeftBottom(count,days) {
      let myechart = this.$echarts.init(this.$refs.echartsINofsbottom);
      let option = {
        textStyle: {
          color: "#fff",
        },
        grid: {
          left: "3%",
          right: "15%",
          bottom: "10%",
          top: "15%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: days,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: count,
            type: "line",
            areaStyle: {},
            itemStyle: {
              color: "#DE11EB",
            },
          },
        ],
      };
      myechart.setOption(option);
    },
    //保定市各县区包装废弃物回收总量排名（TOP5）
    rightTop(area,count) {
      let myechart = this.$echarts.init(this.$refs.echartsINofrightTop);
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "8%",
          top: "18%",
          containLabel: true,
        },
        textStyle: {
          color: "#fff",
        },
        xAxis: {
          type: "category",
          // data: area,
          data: ["徐水区","满城区","清苑区","高阳县","易县"],
        },
        yAxis: {
          type: "value",
          name: "(个)",
          nameTextStyle: {
            padding: [0, 0, 0, -35], // 四个数字分别为上右下左与原位置距离
            fontSize: 10,
          },
        },
        series: [
          {
            label: {
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#14BE20",
                },
              },
            },
            // data: count,
            data: ['998','986','857','843','836'],
            type: "bar",
            itemStyle: {
              color: "#14BE20",
            },
          },
        ],
      };
      myechart.setOption(option);
    },
    //已清运农药包装类型占比
    RightBody(unit,number) {
      let myechart = this.$echarts.init(this.$refs.echartsINofRightBody);
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          textStyle: {
            color: "#fff",
            fontSize:15,
          },
        },
        textStyle: {
          color: "#fff",
          fontSize: 8,
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              {
                value: number[0],
                name: unit[0],
                itemStyle: {
                  color: "#F86D18",
                },
              },
              {
                value: number[1],
                name: unit[1],
                itemStyle: {
                  color: "#188CF8",
                },
              },
              {
                value: number[2],
                name: unit[2],
                itemStyle: {
                  color: "#9DC7F0",
                },
              },
              {
                value: number[3],
                name: unit[3],
                // itemStyle: {
                //   color: "#9DC7F0",
                // },
              },
              {
                value: number[4],
                name: unit[4],
                // itemStyle: {
                //   color: "#9DC7F0",
                // },
              },
              {
                value: number[5],
                name: unit[5],
                // itemStyle: {
                //   color: "#9DC7F0",
                // },
              },
              {
                value: number[6],
                name: unit[6],
                // itemStyle: {
                //   color: "#9DC7F0",
                // },
              },
              {
                value: number[7],
                name: unit[7],
                // itemStyle: {
                //   color: "#9DC7F0",
                // },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myechart.setOption(option);
    },
    //已销售农药品牌类型占比（TOP5）
    RightBodyRight(nylb,number) {
      let myechart = this.$echarts.init(this.$refs.echartsINofRightBodyRight);
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
        },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     mark: { show: true },
        //     dataView: { show: true, readOnly: false },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        series: [
          {
            name: "benmijiahuancuo",
            type: "pie",
            radius: [20, 50],
            // center: ["50%", "50%"],  #188CF8
            roseType: "area",
            itemStyle: {
              borderRadius: 0,
            },
            data: [
              {
                value: number[0],
                name: nylb[0],
                textStyle: {
                  color: "#fff",
                },
                itemStyle: {
                  color: "#188CF8",
                },
              },
              {
                value: number[1],
                name: nylb[1],
                textStyle: {
                  color: "#fff",
                },
                itemStyle: {
                  color: "#FFDF7A",
                },
              },
              {
                value: number[2],
                name: nylb[2],
                textStyle: {
                  color: "#fff",
                },
                itemStyle: {
                  color: "#E3508B",
                },
              },
              {
                value: number[3],
                name: nylb[3],
                textStyle: {
                  color: "#fff",
                },
                itemStyle: {
                  color: "#00E3FF",
                },
              },
              {
                value: number[4],
                name: nylb[4],
                textStyle: {
                  color: "#fff",
                },
                itemStyle: {
                  color: "#FF0000",
                },
              },
            ],
          },
        ],
      };
      myechart.setOption(option);
    },
    // 近七天农药包装回收类型趋势图
    RightbottomRight(daicount,pingcount,days) {
      let myechart = this.$echarts.init(
        this.$refs.echartsINofsrightbottomright
      );
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["农药瓶", "农药袋"],
          textStyle: {
            color: "#fff",
          },
        },
        textStyle: {
          color: "#fff",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "12%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: days,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "农药瓶",
            type: "line",
            stack: "Total",
            data: pingcount,
            itemStyle: {
              color: "#00A0E9",
            },
          },
          {
            name: "农药袋",
            type: "line",
            stack: "Total",
            data: daicount,
            itemStyle: {
              color: "#A337FF",
            },
          },
        ],
      };
      myechart.setOption(option);
    },
  },
};
</script>

<style scoped lang="less">
.nongyao {
  background-image: url(../../assets/image/dabeijing.png);
  background-size: 100vw 100vh;
  width: 100%;
  height: 100%;
  .header {
    background-image: url(../../assets/image/biaoti.png);
    // background-repeat: no-repeat;
    background-size: 100vw 8vh;
    width: 100%;
    height: 8vh;
    .one {
      img {
        height: 4vh;
        margin-left: 0.5vw;
      }
      div {
        color: #cacaca;
        position: absolute;
        top: 0;
        line-height: 4vh;
        left: 3vw;
        font-size: 15px;
      }
    }
    .title {
      color: rgb(35, 222, 253);
      font-size: 2vw;
      text-align: center;
      margin-top: -2.5vh;
    }
    .date {
      color: rgb(35, 222, 253);
      margin-left: 87vw;
      margin-top: -5vh;
      font-size: 0.9vw;
    }
  }
  .left {
    float: left;
    margin-top: 1.5vh;
    margin-left: 1vw;
    width: 30.2vw;
    height: 90vh;
    .headers {
      width: 100%;
      height: 32%;
      background-image: url(../../assets/image/xiaobeijing.png);
      background-size: 100% 100%;
      width: 100%;
      height: 32%;
      p {
        color: rgb(255, 255, 255);
        font-size: 0.9vw;
        border-left: 5px solid #0066ff;
        margin-left: 0.5vw;
        padding-left: 0.5vw;
        padding-top: 1vh;
      }
    }
    .body {
      width: 100%;
      height: 32%;
      margin: 1.5vh 0;
      background-image: url(../../assets/image/xiaobeijing.png);
      background-size: 100% 100%;
      width: 100%;
      height: 32%;
      .npm {
        p {
          color: rgb(255, 255, 255);
          font-size: 0.9vw;
          border-left: 5px solid #0066ff;
          margin-left: 0.5vw;
          padding-left: 0.5vw;
          padding-top: 1vh;
        }
        .echarts {
          margin: 1vw;
          margin-top: 0.8vh;
          // z-index: 999;
          height: 28vh;
          width: 90%;
        }
      }
    }
    .bottom {
      width: 100%;
      height: 32%;
      background-image: url(../../assets/image/xiaobeijing.png);
      background-size: 100% 100%;
      width: 100%;
      height: 32%;
      .npm {
        p {
          color: rgb(255, 255, 255);
          font-size: 0.9vw;
          border-left: 5px solid #0066ff;
          margin-left: 0.5vw;
          padding-left: 0.5vw;
          padding-top: 1vh;
        }
        .echarts {
          margin: 1vw;
          margin-top: 0.8vh;
          // z-index: 999;
          height: 23vh;
          width: 100%;
        }
      }
    }
  }
  .middle {
    float: left;
    margin: 1.5vh 1vw;
    width: 35vw;
    height: 90vh;
    .headers {
      width: 100%;
      height: 10%;
      background-image: url(../../assets/image/xiaotoubu.png);
      background-size: 100% 100%;
      .b {
        float: left;
        height: 100%;
        width: 30%;
        color: #00e3ff;
        .top {
          width: 100%;
          text-align: center;
          line-height: 5vh;
          font-size: 1.4vw;
        }
        .bottomss {
          width: 50%;
          margin-left: 25%;
          background-color: rgb(0, 19, 126);
          text-align: center;
          font-size: 1.3vw;
        }
      }
      .a {
        float: left;
        height: 100%;
        width: 34.7%;
        // background-image: url(../../assets/image/zhibiao_bj.png);
        // background-size: 100% 100%;
        color: #fff;
        text-align: center;
        line-height: 9vh;
        .top {
          color: #00e3ff;
          font-size: 1.1vw;
          display: inline-block;
        }
        .bottomss {
          margin-left: 1vw;
          font-size: 1.3vw;
          display: inline-block;
        }
      }
    }
    .body {
      width: 100%;
      height: 54%;
      margin: 1.5vh 0;
      background-image: url(../../assets/image/ditu_bj.png);
      background-size: 100% 100%;
      width: 100%;
      height: 54%;
      .mapWarp {
        position: absolute;
        width: 33vw;
        height: 45vh;
        top: 21.5vh;
        left: 33.2vw;
        overflow: hidden;
        #container {
          width: 100%;
          height: 100%;
        }
      }
      .abc {
        color: #fff;
        position: absolute;
        z-index: 1112;
        width: 31vw;
        height: 8vh;
        top: 22.5vh;
        left: 34.2vw;
        background-color: rgba(5, 82, 199, 0.7);
        .a {
          width: 33.3%;
          display: inline-block;
          text-align: center;
          div:nth-child(1) {
            line-height: 5vh;
            font-size: 1.3vw;
          }
        }
        .aa {
          div:nth-child(1) {
            color: #eb497f;
          }
        }
        .bb {
          div:nth-child(1) {
            color: #fedf7a;
          }
        }
        .cc {
          div:nth-child(1) {
            color: #00e0ff;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      height: 32%;
      .lefts {
        float: left;
        width: 49%;
        height: 100%;
        margin-right: 2%;
        background-image: url(../../assets/image/xiaobeijing.png);
        background-size: 100% 100%;
        width: 49%;
        height: 100%;
        .npm {
          p {
            color: rgb(255, 255, 255);
            font-size: 0.9vw;
            border-left: 5px solid #0066ff;
            margin-left: 0.5vw;
            padding-left: 0.5vw;
            padding-top: 1vh;
          }
          .echarts {
            margin: 1vw;
            margin-top: 0.8vh;
            // z-index: 999;
            height: 24vh;
            width: 95%;
          }
        }
      }
      .rights {
        float: left;
        width: 49%;
        height: 100%;
        background-image: url(../../assets/image/xiaobeijing.png);
        background-size: 100% 100%;
        width: 49%;
        height: 100%;
        .npm {
          p {
            color: rgb(255, 255, 255);
            font-size: 0.9vw;
            border-left: 5px solid #0066ff;
            margin-left: 0.5vw;
            padding-left: 0.5vw;
            padding-top: 1vh;
          }
          .echarts {
            margin: 1vw;
            margin-top: 0.8vh;
            // z-index: 999;
            height: 24vh;
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }
  .right {
    float: left;
    margin-top: 1.5vh;
    width: 30.2vw;
    height: 90vh;
    .headers {
      width: 100%;
      height: 32%;
      background-image: url(../../assets/image/xiaobeijing.png);
      background-size: 100% 100%;
      width: 100%;
      height: 32%;
      .npm {
        position: relative;
        p {
          color: rgb(255, 255, 255);
          font-size: 0.9vw;
          border-left: 5px solid #0066ff;
          margin-left: 0.5vw;
          padding-left: 0.5vw;
          padding-top: 1vh;
        }
        .echarts {
          margin: 1vw;
          margin-top: 0.8vh;
          height: 24vh;
          width: 85%;
        }
      }
    }
    .body {
      width: 100%;
      height: 32%;
      margin: 1.5vh 0;
      background-image: url(../../assets/image/xiaobeijing.png);
      background-size: 100% 100%;
      .npm {
        p {
          color: rgb(255, 255, 255);
          font-size: 0.9vw;
          border-left: 5px solid #0066ff;
          margin-left: 0.5vw;
          padding-left: 0.5vw;
          padding-top: 1vh;
        }
        .echarts {
          margin: 1vw;
          margin-top: 0.8vh;
          // z-index: 999;
          height: 24vh;
          width: 90%;
        }
      }
    }
    .bottom {
      width: 100%;
      height: 32%;
      background-image: url(../../assets/image/xiaobeijing.png);
      background-size: 100% 100%;
      .npm {
        p {
          color: rgb(255, 255, 255);
          font-size: 0.9vw;
          border-left: 5px solid #0066ff;
          margin-left: 0.5vw;
          padding-left: 0.5vw;
          padding-top: 1vh;
        }
        .echarts {
          margin: 1vw;
          margin-top: 0.8vh;
          // z-index: 999;
          height: 24vh;
          width: 90%;
        }
      }
    }
  }
}
</style>
