<template>
  <div class="pesticides">
    <div class="bottom_top">
      <div class="aa gg">排名</div>
      <div class="aa bb">网点</div>
      <div class="aa cc">销售数量(个)</div>
      <div class="aa dd">销售额(元)</div>
    </div>
    <div class="all">
      <!-- <div class="bottom_bottom" v-for="(item, index) in datas" :key="index"> -->
        <!-- 线上互换 -->
      <div class="bottom_bottom" v-for="(item, index) in rows" :key="index">
        <div class="a ab">{{ index+1 }}</div>
        <div class="a b">{{ item.name }}</div>
        <div class="a c">{{ item.number }}</div>
        <div class="a d">{{ item.price }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { paiming } from '../../network/index'
export default {
  // inject:['reload'],
  data() {
    return {
      rows: [],
      datas:[],
    };
  },
  created() {},
  mounted() {
    this.timer();
    this.getinfodata();
  },
  // watch: {
  //   one() {
  //     this.timer();
  //   },
  // },
  // destroyed() {
  //   clearTimeout(this.timer);
  // },
  methods: {
    //请求接口
    async getinfodata() {
      const { code, data, msg } = await paiming();
      this.datas=data.data
      // this.reload()
    },
    timer() {
      let rows = [
        {
          time: "1",
          name: "保定市徐水区第一农资店",
          number: "13562",
          price: "152365",
        },
        {
          time: "2",
          name: "保定市徐水区第二农资店",
          number: "12462",
          price: "154235",
        },
        {
          time: "3",
          name: "保定市徐水区第三农资店",
          number: "14572",
          price: "245123",
        },
        {
          time: "4",
          name: "保定市徐水区第四农资店",
          number: "15225",
          price: "215543",
        },
        {
          time: "5",
          name: "保定市徐水区第五农资店",
          number: "24583",
          price: "156421",
        },
      ];
      // setTimeout(() => {
        this.rows = rows;
      // }, 1000);
      // this.reload()
    },
  },
};
</script>

<style scoped lang="less">
.pesticides {
  color: #fff;
  .bottom_top {
    line-height: 4vh;
    height: 4vh;
    background-color: rgba(0, 162, 255, 0.2);
    margin: 1vh 1vw;
    color: aqua;
    .aa {
      float: left;
      margin: 0 0.5vw;
      font-size: 0.9vw;
    }
    .gg {
      margin-left: 2vw;
    }
    .bb {
      margin-left: 4vw;
    }
    .cc {
      margin-left: 4vw;
    }
    .dd {
      margin-left: 1.5vw;
    }
  }
  .all {
    height: 18vh;
    margin: 0 1vw;
    overflow-y: auto;
    .bottom_bottom {
      font-size: 0.9vw;
      line-height: 3.5vh;
      .a {
        float: left;
        margin-left: 1vw;
      }
      .ab {
        width: 2vw;
        margin-left: 2vw;
        text-align: center;
      }
      .b {
        width: 10vw;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 0.5vw;
      }
      .c {
        width: 5vw;
        text-align: center;
        margin-left: 0;
      }
      .d {
        width: 5vw;
        text-align: center;
        margin-left: 1.5vw;
      }
    }
  }
  //改变滚动条样式
  .all::-webkit-scrollbar {
    //滚动条整体样式
    width: 4px;
    height: 4px;
  }
  .all::-webkit-scrollbar-thumb {
    //滚动条内部小方块样式
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0.2);
  }
  .all::-webkit-scrollbar-track {
    //滚动条内部轨道
    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0);
    border-radius: 0;
    background: rgba(255, 255, 255, 0);
  }
}
</style>
